<template>
  <div class="container">
    <div class="header">
      <div class="header-title">
        <div class="header-title__text">积分规则配置</div>
      </div>
      <div class="header-close">
        <el-button type="primary" size="small" @click="onSubmit">保 存</el-button>
      </div>
    </div>
    <div class="content">
      <tinymce v-model="content" :height="400"></tinymce>
    </div>
  </div>
</template>
<script>
import tinymce from '@/components/tinymce/editer.vue'

import { getIntegralRuleDetail, submitIntegralRule } from '@/api/integral.js'

export default {
  components: {
    tinymce,
  },
  data() {
    return {
      id: '',
      content: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getIntegralRuleDetail({ data: {} }).then((res) => {
        if (res.success) {
          this.content = res.data.integralRule
          this.id = res.data.id
        }
      })
    },
    onSubmit() {
      submitIntegralRule({
        data: { integralRule: this.content, id: this.id },
      }).then((res) => {
        if (res.success) {
          this.$message.success('保存成功！')
          this.getData()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/base.less';
.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.content {
  padding: 40px;
  box-sizing: border-box;
  border: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 20px;
  position: relative;
  border-bottom: 1px solid #ededed;
  &::before {
    content: '';
    width: 3px;
    height: 18px;
    position: absolute;
    left: -1px;
    top: 50%;
    border-radius: 2px;
    transform: translateY(-50%);
    background-color: #363f47;
  }
  &-title {
    display: flex;
    align-items: center;
    &__text {
      font-size: 14px;
      color: #000;
      font-weight: 500;
      margin-right: 12px;
    }
    &__required {
      font-size: 12px;
      color: #9a9d9e;
      font-weight: 400;
      position: relative;
      &::before {
        content: '*';
        position: absolute;
        left: -6px;
        color: #e86247;
        font-size: 12px;
      }
    }
  }
  &-close {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__icon {
      width: 14px;
      margin-right: 8px;
    }
    &__text {
      font-size: 14px;
      color: #000;
      font-weight: 400;
    }
  }
}
</style>
